import React from "react";
import {Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export function ScrollDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLink = () => {
    handleClose();
    window.location.href = "https://forms.office.com/Pages/ResponsePage.aspx?id=Lo7fAXhHZkyvZw5fuXXBiAemfCaSgXZPolVgAj4NWcpUQTE0S082SEZSRkk0TzZTTktNSzhGUUpUMy4u&wdLOR=cA114E4DF-E65C-1647-8650-693EDDB524F0"
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button style={{ 
                  textTransform: "none",
                  textDecoration: "none",
                  textAlign: "left",
                  color: "grey",}} onClick={handleClickOpen('paper')}><Typography variant="p">Varslingskanal</Typography>
                  </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Informasjon om varslingskanal</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          <Typography variant={"p"} sx={{ mt: 2 }}>
          Verdibasert lederskap handler også om åpenhet. Flexcom Anlegg AS ønsker en lav terskel for å si ifra om kritikkverdige forhold på arbeidsplassen. 
          Dette gir mulighet til å rette opp forhold og til å utvikle virksomheten positivt. 
          Vi har derfor etablert en varslingskanal som gjør det mulig for våre ansatte, 
          kunder og leverandører å varsle om kjennskap eller mistanke til uakseptabel adferd i strid med våre verdier uten frykt for at dette går utover den som varsler.
          </Typography>
          <Typography variant="h6" component="h3" style={{marginTop: 20}}>
          Hva er varsling?
          </Typography>
          <Typography variant={"p"} sx={{ mt: 2 }}>
          Varsling er å gi beskjed om ulovlige eller andre kritikkverdige forhold i virksomheten uten å benytte ordinære rapporteringskanaler. 
          Flexcom Anlegg AS oppfordrer alle til å varsle om forhold som kan true selskapets økonomi eller omdømme.
          </Typography>
          <Typography variant={"h6"} sx={{ mt: 2 }}>
          Eksempler på forhold det kan varsles om:
          </Typography>
          <Typography variant={"p"} sx={{ mt: 2 }}>
          <ul>
            <li>
            Mistanke om økonomiske misligheter (herunder tyveri, korrupsjon, bedrageri, underslag, regnskapslovbrudd eller dokumentforfalskning)
            </li>
            <li>
            Mistanke om andre ulovlige forhold
            </li>
            <li>
            Trakassering eller mobbing, diskriminering eller rasisme
            </li>
            <li>
            Andre brudd på HMS-regler (helse, miljø og sikkerhet)
            </li>
          </ul>
          </Typography>
          <Typography variant="h6" component="h2">
          Hvordan varsle?          
          </Typography>
          <Typography variant={"p"} sx={{ mt: 2 }}>
          Du sender nå inn et varsel Flexcom Anlegg AS som behandler varselet. Det oppfordres til at saken først tas opp med ansvarlig leder, og dernest verneombud eller tillitsvalgt dersom det ikke fører frem å ta det med leder. Hvis det fortsatt er behov for tiltak, benytt varslingskanalen.
          Fyll ut varslingsskjemaet ved å følge denne linken:  
          </Typography>
          <br></br>
          <Button variant="contained" color="success" style={{margin: 15, fontFamily: "'Montserrat', sans-serif",  textTransform: "none", textDecoration: "none"}} onClick={handleLink}>Til varslingsskjema</Button>          <br></br>
          <Typography variant={"p"} sx={{ mt: 2 }}>
          Det er den enkelte som avgjør om varslingskanalen skal benyttes og hva det skal varsles om. Varsleren trenger ikke å legge frem bevis for det de mener er kritikkverdig. 
          Varsler må derimot være så tydelig som mulig på hva som faktisk har skjedd. Varslet bør minimum inneholde følgende:
          </Typography>
          <Typography variant={"p"} sx={{ mt: 2 }}>
          <ul>
            <li>
            tid (tidsperiode) og sted for hendelsen            </li>
            <li>
            spesifikke opplysninger om hva forholdet gjelder, og hva varsleren bygger disse opplysningene på
            </li>
            <li>
            eventuelle personer som varsleren vet eller tror har kunnskap om forholdet
            </li>
          </ul>
          </Typography>
          <Typography variant={"p"} sx={{ mt: 2 }}>
          Den som varsler kan velge å være anonym. Imidlertid vil det være lettere å gå videre med varselet hvis varsler opplyser om sin identitet. 
          Det oppfordres til at varselet gjøres under fullt navn, da dette normalt vil sikre at informasjonen kan etterprøves og verifiseres. 
          Alle som varsler om mulige bekymringer eller kritikkverdige forhold skal kunne gjøre dette uten frykt for videre konsekvenser.
          </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="success" style={{fontFamily: "'Montserrat', sans-serif",  textTransform: "none", textDecoration: "none"}} onClick={handleClose}>Avbryt</Button>
          <Button variant="contained" color="success" style={{fontFamily: "'Montserrat', sans-serif",  textTransform: "none", textDecoration: "none"}} onClick={handleLink}>Til varslingsskjema</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}