import Grid from "@mui/material/Grid";
import "./services.css";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import React from "react";

export function Services() {
  return (
    <div class={"services"} id={"services"}>
      <Container style={{ paddingLeft: 5, paddingRight: 5 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={12}>
            <div className={"topTextInService"}>
              <Typography
                style={{ marginTop: 25, marginBottom: 25 }}
                variant={"h3"}
              >
                Tjenester
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 5 }}>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Vi utfører alt innenfor graving, spesielt rettet mot telecom
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Etablering av nye rør/kabeltrasér
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 5 }}>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Vi prosjekterer og planlegger utbygging innenfor telecom
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Vi benytter sondéutstyr til tolkning av eksisterende rørtrasér
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 5 }}>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Planering, nivåregulering, steinsetting og støttemur
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Vi legger asfalt på alt fra smått til stort
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 5 }}>
          <Grid item xs={6} md={6} lg={6}>
            <div className={"servicesBoxes"}>
              <Typography variant="h5">
                Sammen med vår samarbeidspartnere graver vi for fiberutbygging i
                større prosjekt eller fortetninger
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6} />
        </Grid>
      </Container>
    </div>
  );
}
