import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { Facebook, LinkedIn } from "@mui/icons-material";
import "./contact.css";
import { ScrollDialog } from "./varslingskanal";

export function Contact() {
  return (
    <div id={"contact"}>
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              variant={"h3"}
              style={{
                textAlign: "center",
                marginTop: 50,
                marginBottom: 30,
                color: "grey",
              }}
            >
              Kontakt
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0 }} style={{ textAlign: "center" }}>
          <Grid item xs={12} md={4} lg={4} order={{ xs: 2, md: 1 }}>
            <div style={{ marginTop: 20 }}>
              <a
                style={{
                  textTransform: "none",
                  textDecoration: "none",
                  color: "black",
                }}
                href={
                  "https://www.google.no/maps/place/Flexcom+AS/@59.9908516,10.9180913,17z/data=!3m1!4b1!4m5!3m4!1s0x46417bf81e71eb8d:0x564e07b3e5d43ae0!8m2!3d59.9908489!4d10.92028?hl=no"
                }
              >
                <Typography style={{ color: "grey" }} variant="p">
                  Industriveien 24,<br></br> 1481 Hagan
                </Typography>
                <br />
              </a>
            </div>

            <div style={{ marginTop: 20 }}>
              <a
                style={{
                  textTransform: "none",
                  textDecoration: "none",
                  color: "grey",
                }}
                className={"link"}
                href={"mailto:post@flexcomanlegg.no"}
              >
                <Typography variant="p">post@flexcomanlegg.no</Typography>
              </a>
            </div>

            <ScrollDialog/>    

          </Grid>
          <Grid item xs={12} md={4} lg={4} order={{ xs: 2, md: 1 }}>
            <a href="https://www.linkedin.com">
              <LinkedIn
                style={{ color: "black", marginTop: 5 }}
                sx={{
                  fontSize: { xs: "50px" },
                }}
              />
            </a>
            <a href="https://www.facebook.com/flexcomanlegg">
              <Facebook
                style={{ color: "#1B74E4", marginTop: 5 }}
                sx={{
                  fontSize: { xs: "50px" },
                }}
              />
            </a>
            <div style={{ marginTop: 80, color: "grey" }}>
              <Typography style={{ fontSize: 12 }} variant={"p"}>
                Org.nr. 928 539 725
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4} order={{ xs: 1, md: 2 }}>
            <div
              className="textContact"
              style={{ marginRight: 0, color: "grey" }}
            >
              <Typography variant="p">Våre åpningstider:</Typography>
            </div>
            <div
              className="textContact"
              style={{ marginTop: 5, color: "grey" }}
            >
              <Typography variant="p">
                Man - Tors: 08:00 - 18:00
                <br />
                Fre: 08:00 - 16:00
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div
            style={{
                width: "100%",
                height: 20,
                backgroundColor: "#164973",
                marginTop: 20,
            }}>
        <p className={"mwdesign"}>Utviklet av MWdesign</p>
      </div>
    </div>
  )
}
