import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import "./imageCarousel.css";

export function Example(props) {
  let items = [
    {
      image:
        "https://images.unsplash.com/photo-1649829396464-9f743b1620c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
    },
    {
      image:
        "https://images.unsplash.com/photo-1527199768775-bdabf8b32f61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
    },
    {
      image:
        "https://images.unsplash.com/photo-1599707254554-027aeb4deacd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2071&q=80",
    },
    {
      image:
        "https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80",
    },
  ];

  return (
    <Carousel autoPlay={true} duration={350} stopAutoPlayOnHover={false}>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <div id={"home"}>
      <img className={"imagesCarousel"} src={props.item.image} />
      <div id={"home"} className="topBoxContainer">
        <Grid container spacing={0}>
          <Grid item xs={4} sm={4}>
            <div class={"greenBox1"}>
              <div>
                VI UTFØRER GRAVING, NEDFØRING AV RØR, STEINSETTING OG
                ASFALTERING
              </div>
            </div>
          </Grid>
          <Grid item xs={4} sm={4}>
            <div className={"greyBox"}>
              <div>PROSJEKTERING OG UTBYGGING</div>
            </div>
          </Grid>

          <Grid item xs={4} sm={4}>
            <div class={"greenBox2"}>
              <div>FIBEROPTIKK, TELECOM, TOLKING AV RØR OG SONDESØK</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
