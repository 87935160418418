import React from "react";
import Grid from "@mui/material/Grid";
import { Container, Typography } from "@mui/material";

export function About() {
  return (
    <Container maxWidth={"lg"} style={{ marginTop: 50 }} id={"about"}>
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant={"h3"} style={{ color: "#696969" }}>
            Velkommen til Flexcom Anlegg
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={5} marginTop={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant={"p"} style={{ color: "grey" }}>
            Flexcom anlegg er et nyetablert selskap innenfor anlegg, etablert i
            Viken. Vi sikter oss inn på telecom markedet, men vi utfører alt
            innenfor anleggstjenester. Vi graver, legger rør, fyller igjen og
            asfalterer. Vi skal videre være med på utbygging og utvide
            infrastruktur for fremtiden. Mens vårt co- selskap Flexcom AS
            utfører alt innenfor service og montering på fiber og coaxial for tv
            og internett.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant={"p"} style={{ color: "grey" }}>
            Sammen med våre samarbeidspartnere, har vi utført over tusen
            installasjoner og har vært med på å bygge ut store områder med fiber
            i hele Oslo og Viken området. Vi har noen av norges største
            teleselskaper, som våre primær-partnere. Sammen er vi en total
            leverandør innenfor fiberinstallasjoner.
            <br />
            Vårt fokus ligger i punktlighet, kommunikasjon og kvalitet.
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} marginTop={8}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          style={{ textAlign: "center", color: "#696969" }}
        >
          <Typography variant={"h4"}>
            Vi tror faktisk dette har noe med vårt fokus å gjøre:
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} marginTop={3}>
        <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
          <Typography
            variant={"h3"}
            style={{ color: "#696969" }}
            display={{ xs: "none", md: "block" }}
          >
            SAMARBEID – KOMMUNIKASJON – KVALITET
          </Typography>
          <Typography
            variant={"h3"}
            style={{ color: "#696969" }}
            display={{ xs: "block", md: "none" }}
          >
            SAMARBEID
            <br />
            KOMMUNIKASJON
            <br />
            KVALITET
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
